;(function(){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  name: 'Login',

  data: function data() {
    return {
      form: {
        user: '',
        password: ''
      }
    };
  },
  mounted: function mounted() {
    if (this.$store.getters.isLoggedIn) {
      this.$store.dispatch('addFlash', {
        type: 'warning',
        message: 'Vous êtes déjà loggué'
      });
      this.$router.push({ name: 'home' });
    }
  },

  methods: {
    onSubmit: function onSubmit() {
      var _this = this;

      this.$auth(this.form.user, this.form.password).then(function () {
        _this.$router.push({ name: 'home' });
      }).catch(function (httpResponse) {
        _this.$store.dispatch('addFlash', {
          type: 'danger',
          message: 'Erreur de login (' + httpResponse.status + ' - ' + httpResponse.statusText + ')'
        });
      });
    }
  }
};
})()
if (module.exports.__esModule) module.exports = module.exports.default
var __vue__options__ = (typeof module.exports === "function"? module.exports.options: module.exports)
if (__vue__options__.functional) {console.error("[vueify] functional components are not supported and should be defined in plain js files using render functions.")}
__vue__options__.render = function render () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-form',{staticClass:"container",on:{"submit":function($event){$event.preventDefault();_vm.onSubmit($event)}}},[_c('b-form-group',{attrs:{"label":"Username:","label-for":"username"}},[_c('b-form-input',{attrs:{"id":"username","type":"text","required":"","placeholder":"Enter login"},model:{value:(_vm.form.user),callback:function ($$v) {_vm.$set(_vm.form, "user", $$v)},expression:"form.user"}})],1),_vm._v(" "),_c('b-form-group',{attrs:{"label":"Password:","label-for":"password"}},[_c('b-form-input',{attrs:{"id":"password","type":"password","required":"","placeholder":"Enter password"},model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", $$v)},expression:"form.password"}})],1),_vm._v(" "),_c('b-button',{attrs:{"type":"submit","variant":"primary"}},[_vm._v("Login")])],1)}
__vue__options__.staticRenderFns = []
if (module.hot) {(function () {  var hotAPI = require("vue-hot-reload-api")
  hotAPI.install(require("vue"), true)
  if (!hotAPI.compatible) return
  module.hot.accept()
  if (!module.hot.data) {
    hotAPI.createRecord("data-v-06cc744f", __vue__options__)
  } else {
    hotAPI.reload("data-v-06cc744f", __vue__options__)
  }
})()}