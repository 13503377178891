;(function(){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  name: 'Flash',
  data: function data() {
    return {
      dismissSecs: 5,
      dismissCountDown: 0,
      showDismissibleAlert: false
    };
  },

  computed: {
    hasFlash: function hasFlash() {
      return !!this.$store.getters.flash.type;
    },
    flash: function flash() {
      if (this.hasFlash) {
        this.showAlert();
      }
      return this.$store.getters.flash;
    }
  },
  methods: {
    countDownChanged: function countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown;

      if (!dismissCountDown) {
        this.$store.dispatch('removeFlash');
      }
    },
    showAlert: function showAlert() {
      this.dismissCountDown = this.dismissSecs;
    }
  }
};
})()
if (module.exports.__esModule) module.exports = module.exports.default
var __vue__options__ = (typeof module.exports === "function"? module.exports.options: module.exports)
if (__vue__options__.functional) {console.error("[vueify] functional components are not supported and should be defined in plain js files using render functions.")}
__vue__options__.render = function render () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-alert',{attrs:{"show":_vm.dismissCountDown,"variant":_vm.flash.type},on:{"dismissed":function($event){_vm.dismissCountDown=0},"dismiss-count-down":_vm.countDownChanged}},[_c('p',{domProps:{"innerHTML":_vm._s(_vm.flash.message)}})])}
__vue__options__.staticRenderFns = []
if (module.hot) {(function () {  var hotAPI = require("vue-hot-reload-api")
  hotAPI.install(require("vue"), true)
  if (!hotAPI.compatible) return
  module.hot.accept()
  if (!module.hot.data) {
    hotAPI.createRecord("data-v-2b0eb964", __vue__options__)
  } else {
    hotAPI.reload("data-v-2b0eb964", __vue__options__)
  }
})()}