;(function(){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _BaseForm = require('../components/items/BaseForm.vue');

var _BaseForm2 = _interopRequireDefault(_BaseForm);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
  name: 'Add',
  components: {
    BaseForm: _BaseForm2.default
  },

  methods: {
    ok: function ok(httpResponse) {
      this.$store.dispatch('addFlash', {
        type: 'success',
        message: httpResponse.body.name + ' sauvegardé'
      });
      this.$router.push({ name: 'get', params: { id: httpResponse.body.id } });
    },
    error: function error(httpResponse) {
      var message = httpResponse.body.message + '<br />';
      message += '<ul>';
      for (var fieldName in httpResponse.body.errors.children) {
        var fieldErrors = httpResponse.body.errors.children[fieldName].errors;
        if (fieldErrors) {
          message += '<li>' + fieldName + ': ' + fieldErrors.join(', ') + '</li>';
        }
      }
      message += '</ul>';

      this.$store.dispatch('addFlash', {
        type: 'danger',
        message: message
      });
    },
    onSubmit: function onSubmit(miam) {
      this.$store.dispatch('saveMiam', miam).then(this.ok).catch(this.error);
    }
  }
};
})()
if (module.exports.__esModule) module.exports = module.exports.default
var __vue__options__ = (typeof module.exports === "function"? module.exports.options: module.exports)
if (__vue__options__.functional) {console.error("[vueify] functional components are not supported and should be defined in plain js files using render functions.")}
__vue__options__.render = function render () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container"},[_c('BaseForm',{attrs:{"submitValue":"Créer"},on:{"submit":_vm.onSubmit}})],1)}
__vue__options__.staticRenderFns = []
if (module.hot) {(function () {  var hotAPI = require("vue-hot-reload-api")
  hotAPI.install(require("vue"), true)
  if (!hotAPI.compatible) return
  module.hot.accept()
  if (!module.hot.data) {
    hotAPI.createRecord("data-v-7e2c05f2", __vue__options__)
  } else {
    hotAPI.reload("data-v-7e2c05f2", __vue__options__)
  }
})()}