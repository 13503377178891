;(function(){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _SearchFormDumb = require('./SearchFormDumb');

var _SearchFormDumb2 = _interopRequireDefault(_SearchFormDumb);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
  name: 'SearchFormSmart',
  props: ['searchIcon'],
  components: { SearchFormDumb: _SearchFormDumb2.default },
  data: function data() {
    return {
      searchValue: ''
    };
  },
  mounted: function mounted() {
    this.updateSearchValue();
  },

  watch: {
    '$route': function $route(to) {
      if (to.name !== 'search') {
        return;
      }
      this.updateSearchValue();
    }
  },

  methods: {
    updateSearchValue: function updateSearchValue() {
      this.searchValue = this.$route.query.q || '';
    },
    searchUpdated: function searchUpdated(searchValue) {
      this.searchValue = searchValue;

      var searchRoute = { name: 'search', query: { q: searchValue } };
      if (this.$route.name === 'search') {
        this.$router.replace(searchRoute);
      } else {
        this.$router.push(searchRoute);
      }
    }
  }
};
})()
if (module.exports.__esModule) module.exports = module.exports.default
var __vue__options__ = (typeof module.exports === "function"? module.exports.options: module.exports)
if (__vue__options__.functional) {console.error("[vueify] functional components are not supported and should be defined in plain js files using render functions.")}
__vue__options__.render = function render () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('SearchFormDumb',{attrs:{"search-icon":_vm.searchIcon,"search-value":_vm.searchValue},on:{"updated-word":_vm.searchUpdated}})}
__vue__options__.staticRenderFns = []
if (module.hot) {(function () {  var hotAPI = require("vue-hot-reload-api")
  hotAPI.install(require("vue"), true)
  if (!hotAPI.compatible) return
  module.hot.accept()
  if (!module.hot.data) {
    hotAPI.createRecord("data-v-39a1b04a", __vue__options__)
  } else {
    hotAPI.reload("data-v-39a1b04a", __vue__options__)
  }
})()}