;(function(){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _BaseForm = require('../components/items/BaseForm.vue');

var _BaseForm2 = _interopRequireDefault(_BaseForm);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
  name: 'Edit',
  components: {
    BaseForm: _BaseForm2.default
  },
  props: ['id'],

  data: function data() {
    return {
      loaded: false
    };
  },


  computed: {
    miam: function miam() {
      return this.$store.getters.miam;
    }
  },

  mounted: function mounted() {
    var _this = this;

    this.$store.dispatch('selectItem', this.id).then(function () {
      _this.loaded = true;
    });
  },


  methods: {
    ok: function ok(httpResponse) {
      this.$store.dispatch('addFlash', {
        type: 'success',
        message: httpResponse.body.name + ' a bien été mis à jour'
      });
      this.$router.push({ name: 'get', params: { id: httpResponse.body.id } });
    },
    error: function error(httpResponse) {
      this.$store.dispatch('addFlash', {
        type: 'danger',
        message: httpResponse.body.message.replace(/\n/g, '<br />\n').replace(/\s{4}ERROR/g, '&nbsp;&nbsp;&nbsp;&nbsp;ERROR')
      });
    },
    onSubmit: function onSubmit(miam) {
      this.$store.dispatch('updateMiam', { id: this.miam.id, miam: miam }).then(this.ok).catch(this.error);
    }
  }
};
})()
if (module.exports.__esModule) module.exports = module.exports.default
var __vue__options__ = (typeof module.exports === "function"? module.exports.options: module.exports)
if (__vue__options__.functional) {console.error("[vueify] functional components are not supported and should be defined in plain js files using render functions.")}
__vue__options__.render = function render () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container"},[(_vm.loaded)?_c('BaseForm',{attrs:{"name":_vm.miam.name,"last-miam":_vm.miam.lastMiam,"frequency":_vm.miam.frequency,"image-name":_vm.miam.imageName,"image-id":_vm.miam.imageId,"submitValue":"Mettre à jour"},on:{"submit":_vm.onSubmit}}):_vm._e()],1)}
__vue__options__.staticRenderFns = []
if (module.hot) {(function () {  var hotAPI = require("vue-hot-reload-api")
  hotAPI.install(require("vue"), true)
  if (!hotAPI.compatible) return
  module.hot.accept()
  if (!module.hot.data) {
    hotAPI.createRecord("data-v-081c4b14", __vue__options__)
  } else {
    hotAPI.reload("data-v-081c4b14", __vue__options__)
  }
})()}