import * as d3 from 'd3'
import moment from 'moment'

const width = 960
const height = 136
const cellSize = 17
const DOMAIN_BEGIN = 2629740
const DOMAIN_END = 10518960
let rect

// get day return 0 for sunday ... monday should do that
const fixGetDay = function (day) {
  day--
  if (day < 0) {
    day = 6
  }
  return day
}

// build stroke for each month
function pathMonth (t0) {
  const t1 = new Date(t0.getFullYear(), t0.getMonth() + 1, 0)
  const d0 = fixGetDay(t0.getDay())
  const w0 = d3.timeMonday.count(d3.timeYear(t0), t0)
  const d1 = fixGetDay(t1.getDay())
  const w1 = d3.timeMonday.count(d3.timeYear(t1), t1)

  return 'M' + (w0 + 1) * cellSize + ',' + d0 * cellSize +
    'H' + w0 * cellSize + 'V' + 7 * cellSize +
    'H' + w1 * cellSize + 'V' + (d1 + 1) * cellSize +
    'H' + (w1 + 1) * cellSize + 'V' + 0 +
    'H' + (w0 + 1) * cellSize + 'Z'
}

const color = d3.scaleQuantize()
  .domain([DOMAIN_BEGIN, DOMAIN_END])
  .range([
    '#66bd63',
    '#a6d96a',
    '#fee08b',
    '#fdae61',
    '#f46d43'
  ])

function addYearLegend (svg) {
  svg.append('text')
    .attr('transform', 'translate(-6,' + cellSize * 3.5 + ')rotate(-90)')
    .attr('font-family', 'sans-serif')
    .attr('font-size', 10)
    .attr('text-anchor', 'middle')
    .text(function (d) { return d })
}

function addEachDayBoxes (svg) {
  rect = svg.append('g')
    .attr('fill', 'none')
    .attr('stroke', '#ccc')
    .selectAll('rect')
    .data(function (d) { return d3.timeDays(new Date(d, 0, 1), new Date(d + 1, 0, 1)) })
    .enter().append('rect')
    .attr('width', cellSize)
    .attr('height', cellSize)
    .attr('x', function (d) { return d3.timeMonday.count(d3.timeYear(d), d) * cellSize })
    .attr('y', function (d) { return fixGetDay(d.getDay()) * cellSize })
    .datum(d3.timeFormat('%d/%m/%Y'))
}

function createGraphBlock () {
  const currentYear = new Date().getFullYear()

  return d3.select('div#graph')
    .selectAll('svg')
    .data(d3.range(currentYear, currentYear + 1))
    .enter().append('svg')
    .attr('width', width)
    .attr('height', height)
    .append('g')
    .attr('transform', 'translate(' + ((width - cellSize * 53) / 2) + ',' + (height - cellSize * 7 - 1) + ')')
}

function addMonthStrokes (svg) {
  svg.append('g')
    .attr('fill', 'none')
    .attr('stroke', '#000')
    .selectAll('path')
    .data(function (d) { return d3.timeMonths(new Date(d, 0, 1), new Date(d + 1, 0, 1)) })
    .enter().append('path')
    .attr('d', pathMonth)
}

function colorDays (data) {
  rect.filter(function (d) { return d in data })
    .attr('fill', function (d) { return color(data[d].frequencyNum) })
    .append('title')
    .text(function (d) { return d + ': ' + data[d].label + ' (' + data[d].frequency + ')' })
}

function colorUntilDays (data, dateUntil) {
  rect.filter(function (d) {
    const dm = moment(d + ' +0000', 'DD/MM/YYYY Z') // if not specified use the current TZ (usually +1)
    return dm.isBefore(dateUntil, 'day') && !(d in data)
  })
    .attr('fill', '#eaeaea')
    .append('title')
    .text(function (d) { return d })
}

export function init () {
  const svg = createGraphBlock()
  addYearLegend(svg)
  addEachDayBoxes(svg)
  addMonthStrokes(svg)
}

export function update (dateUntil, data) {
  colorUntilDays(data, dateUntil)
  colorDays(data)
}
