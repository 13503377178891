;(function(){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _moment = require('moment');

var _moment2 = _interopRequireDefault(_moment);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
  name: 'BaseForm',
  props: {
    name: {
      type: String,
      default: ''
    },
    lastMiam: {
      type: String,
      default: (0, _moment2.default)().format('YYYY-MM-DD')
    },
    frequency: {
      type: String,
      default: null
    },
    imageName: {
      type: String,
      default: ''
    },
    imageId: {
      type: Number,
      default: null
    },
    submitValue: {
      type: String,
      required: true
    }

  },
  data: function data() {
    var lM = (0, _moment2.default)(this.lastMiam).format('YYYY-MM-DD');

    return {
      placeholder: this.imageName ? this.imageName : 'Choisissez une image',
      uploadProgress: false,
      dName: this.name,
      dLastMiam: lM,
      dFrequency: this.frequency,
      dFile: this.fileMiam,
      fileId: this.imageId,
      submitValueButton: this.submitValue
    };
  },

  computed: {
    isValidForm: function isValidForm() {
      var isValidForm = this.dName && this.dLastMiam && this.dFrequency && !this.uploadProgress;
      this.submitValueButton = isValidForm ? this.submitValue : '...';
      return isValidForm;
    },
    frequencies: function frequencies() {
      return this.$store.getters.frequencies;
    }
  },
  methods: {
    input: function input(file) {
      var _this = this;

      this.uploadProgress = true;
      this.$store.dispatch('saveFile', file).then(function (id) {
        _this.uploadProgress = false;
        _this.fileId = id;
      });
    },
    onSubmit: function onSubmit() {
      this.$emit('submit', {
        name: this.dName,
        lastMiam: this.dLastMiam,
        frequency: this.dFrequency,
        file: this.fileId
      });
    }
  }
};
})()
if (module.exports.__esModule) module.exports = module.exports.default
var __vue__options__ = (typeof module.exports === "function"? module.exports.options: module.exports)
if (__vue__options__.functional) {console.error("[vueify] functional components are not supported and should be defined in plain js files using render functions.")}
__vue__options__.render = function render () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-form',{on:{"submit":function($event){$event.preventDefault();_vm.onSubmit($event)}}},[_c('b-form-group',{attrs:{"label":"Nom:","label-for":"miamName"}},[_c('b-form-input',{attrs:{"id":"miamName","type":"text","placeholder":"Le nom du plat"},model:{value:(_vm.dName),callback:function ($$v) {_vm.dName=$$v},expression:"dName"}})],1),_vm._v(" "),_c('b-form-group',{attrs:{"label":"Dernier miam:","label-for":"lastMiam"}},[_c('b-form-input',{attrs:{"id":"lastMiam","type":"date","placeholder":"Dernier miam"},model:{value:(_vm.dLastMiam),callback:function ($$v) {_vm.dLastMiam=$$v},expression:"dLastMiam"}})],1),_vm._v(" "),_c('b-form-group',{attrs:{"label":"Fréquence:","label-for":"frequencyMiam"}},[_c('b-form-select',{staticClass:"mb-3",attrs:{"id":"frequencyMiam","options":_vm.frequencies},model:{value:(_vm.dFrequency),callback:function ($$v) {_vm.dFrequency=$$v},expression:"dFrequency"}})],1),_vm._v(" "),_c('b-form-group',{attrs:{"label":"Image:","label-for":"imageMiam"}},[_c('b-form-file',{attrs:{"id":"imageMiam","accept":"image/*","placeholder":_vm.placeholder,"choose-label":"Fichier"},on:{"input":_vm.input},model:{value:(_vm.dFile),callback:function ($$v) {_vm.dFile=$$v},expression:"dFile"}})],1),_vm._v(" "),_c('b-button',{attrs:{"type":"submit","variant":"primary","disabled":! _vm.isValidForm},domProps:{"textContent":_vm._s(_vm.submitValueButton)}})],1)}
__vue__options__.staticRenderFns = []
if (module.hot) {(function () {  var hotAPI = require("vue-hot-reload-api")
  hotAPI.install(require("vue"), true)
  if (!hotAPI.compatible) return
  module.hot.accept()
  if (!module.hot.data) {
    hotAPI.createRecord("data-v-42dab632", __vue__options__)
  } else {
    hotAPI.reload("data-v-42dab632", __vue__options__)
  }
})()}