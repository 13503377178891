;(function(){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _miam = require('../components/items/miam.vue');

var _miam2 = _interopRequireDefault(_miam);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
  components: {
    'miam': _miam2.default
  },

  computed: {
    miams: function miams() {
      return this.$store.getters.randomMiams;
    }
  }
};
})()
if (module.exports.__esModule) module.exports = module.exports.default
var __vue__options__ = (typeof module.exports === "function"? module.exports.options: module.exports)
if (__vue__options__.functional) {console.error("[vueify] functional components are not supported and should be defined in plain js files using render functions.")}
__vue__options__.render = function render () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row"},_vm._l((_vm.miams),function(miam){return _c('div',{staticClass:"col-lg-3 col-md-4 col-sm-6"},[_c('miam',{attrs:{"miam":miam}})],1)}))])}
__vue__options__.staticRenderFns = []
if (module.hot) {(function () {  var hotAPI = require("vue-hot-reload-api")
  hotAPI.install(require("vue"), true)
  if (!hotAPI.compatible) return
  module.hot.accept()
  if (!module.hot.data) {
    hotAPI.createRecord("data-v-e8b7412e", __vue__options__)
  } else {
    hotAPI.reload("data-v-e8b7412e", __vue__options__)
  }
})()}