import Vue from 'vue'
import BootstrapVue from 'bootstrap-vue'
import VueRouter from 'vue-router'
import VueResource from 'vue-resource'
import VueLocalStorage from 'vue-ls'

import Flash from './components/items/flash.vue'
import Navbar from './components/items/Navbar.vue'

import router from './router'
import store from './store/miams'
import VueAuth from './lib/authentification'

// This line is only to force bootstrap load.
require('bootstrap')
require('babel-polyfill')
require('babel-runtime/core-js/promise')
require('d3')

// a load here is needed to use it after
require('moment')
require('moment/locale/fr.js')

Vue.use(VueLocalStorage, {
  namespace: 'vuejs__'
})
Vue.use(VueResource)
Vue.use(BootstrapVue)
Vue.use(VueRouter)
Vue.use(VueAuth, {store})

// eslint-disable-next-line no-new
new Vue({
  el: '#app',
  router,
  store,
  components: {
    Navbar,
    Flash
  },
  created () {
    this.$store.dispatch('random')
  }
})
