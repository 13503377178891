import Vue from 'vue'
import Vuex from 'vuex'
import moment from 'moment'
import VueResource from 'vue-resource'

/* global FormData */

Vue.use(VueResource)
Vue.use(Vuex)

const miamsGetActions = {
  getLast: {method: 'GET', params: {last: 1}},
  getSearch: {method: 'GET'},
  updatePartial: {method: 'PATCH'}
}

const miamsResource = Vue.resource('miams{/id}', {}, miamsGetActions)
const filesResource = Vue.resource('files{/id}')
const authTokensResource = Vue.resource('auth-tokens{/id}')
const lastChecksResource = Vue.resource('last-checks{/id}')

export default new Vuex.Store({
  state: {
    mode: 'random',
    miams: {},
    lastList: [],
    searchList: [],
    randomList: [],
    currentMiamId: '',
    lastCheck: '',

    flash: {},

    auth: {
      user: {
        username: '',
        roles: []
      }
    }
  },

  getters: {
    isLoggedIn (state) {
      return state.auth.user.username !== ''
    },
    lastMiams (state) {
      let miams = []

      for (let id of state.lastList) {
        if (!state.miams.hasOwnProperty(id)) {
          console.warn('id skipped ', id)
          continue
        }
        miams.push(state.miams[id])
      }

      return miams
    },
    searchMiams (state) {
      let miams = []

      for (let id of state.searchList) {
        if (!state.miams.hasOwnProperty(id)) {
          console.warn('id skipped ', id)
          continue
        }
        miams.push(state.miams[id])
      }

      return miams
    },
    randomMiams (state) {
      let miams = []

      for (let id of state.randomList) {
        if (!state.miams.hasOwnProperty(id)) {
          console.warn('id skipped ', id)
          continue
        }
        miams.push(state.miams[id])
      }

      return miams
    },
    miam (state) {
      return state.miams[state.currentMiamId]
    },
    frequencies (state) {
      return [
        {value: null, text: 'Prière de choisir une option', disabled: true},
        {value: 'P14D', text: '2 semaines'},
        {value: 'P1M', text: '1 mois'},
        {value: 'P2M', text: '2 mois'},
        {value: 'P3M', text: '3 mois'},
        {value: 'P4M', text: '4 mois'},
        {value: 'P5M', text: '5 mois'},
        {value: 'P6M', text: '6 mois'}
      ]
    },
    flash (state) {
      return state.flash
    },
    lastCheck (state) {
      return state.lastCheck
    }
  },
  actions: {
    authLoaded ({commit}, auth) {
      commit('authLoaded', auth)
    },
    saveMiam ({commit}, form) {
      return miamsResource.save(form).then((httpResponse) => {
        commit('saveMiam', httpResponse.body)
        return httpResponse
      })
    },
    updateMiam ({commit}, {id, miam}) {
      return miamsResource.update({id}, miam).then((httpResponse) => {
        commit('saveMiam', httpResponse.body)
        return httpResponse
      })
    },
    removeMiam ({commit}, {id}) {
      return miamsResource.delete({id}).then((httpResponse) => {
        commit('removeMiam', id)
        return httpResponse
      })
    },
    saveFile ({commit, dispatch}, fileInput) {

      const formData = new FormData()

      formData.append('imageFile', fileInput, fileInput.name)

      return filesResource.save(formData).then((response) => {
        return response.body
      }).catch((error) => {
        dispatch('addFlash', {
          type: 'danger',
          message: 'Erreur: ' + error.status + ' - ' + error.statusText
        })
      })
    },
    selectItem ({commit, state}, currentMiamId) {
      if (state.miams.hasOwnProperty(currentMiamId)) {
        commit('currentMiam', {id: currentMiamId})
      } else {
        return miamsResource.get({id: currentMiamId}).then((response) => {
          commit('fillMiam', [response.body])
          commit('currentMiam', {id: currentMiamId})
        })
      }
    },
    addFlash ({commit}, flash) {
      commit('addFlash', flash)
    },
    removeFlash ({commit, state}) {
      if (state.flash) {
        commit('removeFlash')
      }
    },
    eatMiam ({commit}, miam) {
      return miamsResource.updatePartial({id: miam.id}, {lastMiam: moment().format('YYYY-MM-DD')}).then((httpResponse) => {
        commit('eatMiam', httpResponse.body)
        return httpResponse
      })
    },
    lastMiam ({commit}) {
      commit('setMode', 'last')
      return miamsResource.getLast().then((response) => {
        commit('fillMiam', response.body)
        commit('lastList', response.body.map((item) => { return item.id }))
      }, (response) => {
        console.error(response)
      })
    },
    setSearch ({commit}, searchTerm) {
      commit('setMode', 'search')
      return miamsResource.getSearch({search: searchTerm}).then((response) => {
        commit('fillMiam', response.body)
        commit('searchList', response.body.map((item) => { return item.id }))
      }, (response) => {
        console.error(response)
      })
    },

    random ({commit}) {
      commit('setMode', 'random')
      return miamsResource.get().then((response) => {
        commit('fillMiam', response.body)
        commit('randomList', response.body.map((item) => { return item.id }))
      }, (response) => {
        console.error(response)
      })
    },
    createAuthToken ({commit}, {login, password}) {
      return authTokensResource.save({login, password})
    },
    deleteAuthToken ({commit}) {
      return authTokensResource.delete()
    },
    createLastCheck ({commit}) {
      return lastChecksResource.save().then((lastCheck) => {
        commit('lastCheck', lastCheck.body.lastCheckAt)
      })
    },
    readLastCheck ({commit}) {
      return lastChecksResource.get().then((lastCheck) => {
        commit('lastCheck', lastCheck.body.lastCheckAt)
      })
    }
  },
  mutations: {
    authLoaded (state, authPayload) {
      state.auth.user.username = authPayload.user.username
      Vue.set(state.auth.user, 'roles', authPayload.user.roles)
    },
    saveMiam (state, updatedMiam) {
      if (state.miams.hasOwnProperty(updatedMiam.id)) {
        const miam = state.miams[updatedMiam.id]
        miam.name = updatedMiam.name
        miam.dateToEat = updatedMiam.dateToEat
        miam.frequency = updatedMiam.frequency
        miam.imageFile = updatedMiam.imageFile
        miam.imageName = updatedMiam.imageName
        miam.lastMiam = updatedMiam.lastMiam
        return
      }
      Vue.set(state.miams, updatedMiam.id, updatedMiam)
    },
    removeMiam (state, id) {
      if (!state.miams.hasOwnProperty(id)) {
        return
      }
      Vue.delete(state.miams, id)
    },
    addFlash (state, flash) {
      state.flash = flash
    },
    removeFlash (state) {
      state.flash = {}
    },
    setMode (state, mode) {
      state.mode = mode
    },
    fillMiam (state, miams) {
      for (let miamItem of miams) {
        Vue.set(state.miams, miamItem.id, miamItem)
      }
    },
    currentMiam (state, {id}) {
      state.currentMiamId = id
    },
    eatMiam (state, {id, lastMiam, dateToEat}) {
      state.miams[id].dateToEat = dateToEat
      state.miams[id].lastMiam = lastMiam

      const index = state.randomList.indexOf(id)
      state.randomList.splice(index, 1)
    },
    lastList (state, list) {
      state.lastList = list
    },
    searchList (state, list) {
      state.searchList = list
    },
    randomList (state, list) {
      state.randomList = list
    },
    lastCheck(state, at) {
      state.lastCheck = at
    }

  }

})
