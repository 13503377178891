import VueRouter from 'vue-router'
import Home from './page/home.vue'
import Search from './page/search.vue'
import Get from './page/get.vue'
import Add from './page/add.vue'
import Edit from './page/edit.vue'
import LastMiams from './page/last-miams.vue'
import Login from './page/login.vue'

const routes = [
  {name: 'home', path: '/', component: Home},
  {name: 'login', path: '/login', component: Login},
  {name: 'search', path: '/search', component: Search, props: (route) => ({ query: route.query.q }) },
  {name: 'add', path: '/add', component: Add},
  {name: 'edit', path: '/edit/:id', component: Edit, props: true},
  {name: 'get', path: '/get/:id', component: Get, props: true},
  {name: 'lastEat', path: '/last', component: LastMiams}
]

export default new VueRouter({
  routes
})
