;(function(){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _promise = require('babel-runtime/core-js/promise');

var _promise2 = _interopRequireDefault(_promise);

var _miamli = require('../components/items/miamli.vue');

var _miamli2 = _interopRequireDefault(_miamli);

var _d3Weeks = require('../lib/d3-weeks');

var D3Weeks = _interopRequireWildcard(_d3Weeks);

var _moment = require('moment');

var _moment2 = _interopRequireDefault(_moment);

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
  components: {
    'miamli': _miamli2.default
  },

  computed: {
    miams: function miams() {
      return this.$store.getters.lastMiams;
    },
    lastCheck: function lastCheck() {
      return this.$store.getters.lastCheck;
    },
    lastCheckWas: function lastCheckWas() {
      return 'Last check was : ' + (0, _moment2.default)(this.lastCheck).format('DD/MM/YYYY');
    }
  },

  mounted: function mounted() {
    D3Weeks.init();
    _promise2.default.all([this.$store.dispatch('readLastCheck'), this.$store.dispatch('lastMiam')]).then(this.updateD3js);
  },

  methods: {
    onClick: function onClick() {
      this.$store.dispatch('createLastCheck').then(this.updateD3js);
    },
    updateD3js: function updateD3js() {
      var data = {};
      for (var miamId in this.miams) {
        var miam = this.miams[miamId];
        data[(0, _moment2.default)(miam.lastMiam).format('DD/MM/YYYY')] = {
          label: miam.name,
          frequency: miam.frequency,
          frequencyNum: miam.frequencyNumber
        };
      }

      D3Weeks.update((0, _moment2.default)(this.lastCheck), data);
    }
  }
};
})()
if (module.exports.__esModule) module.exports = module.exports.default
var __vue__options__ = (typeof module.exports === "function"? module.exports.options: module.exports)
if (__vue__options__.functional) {console.error("[vueify] functional components are not supported and should be defined in plain js files using render functions.")}
__vue__options__.render = function render () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container-fluid"},[_vm._l((_vm.miams.slice(0, 10)),function(miam){return _c('miamli',{attrs:{"miam":miam}})}),_vm._v(" "),_c('div',{attrs:{"id":"graph"}}),_vm._v(" "),_c('p',{domProps:{"textContent":_vm._s(_vm.lastCheckWas)}}),_vm._v(" "),_c('button',{on:{"click":_vm.onClick}},[_vm._v("Valid empty days")])],2)}
__vue__options__.staticRenderFns = []
if (module.hot) {(function () {  var hotAPI = require("vue-hot-reload-api")
  hotAPI.install(require("vue"), true)
  if (!hotAPI.compatible) return
  module.hot.accept()
  if (!module.hot.data) {
    hotAPI.createRecord("data-v-ab00a184", __vue__options__)
  } else {
    hotAPI.reload("data-v-ab00a184", __vue__options__)
  }
})()}