const LS_KEY = 'auth'

function deAuth (Vue, store) {
  Vue.ls.remove(LS_KEY)
  store.dispatch('authLoaded', {user: {username: '', roles: []}})
  window.location.reload(false)
}

function doAuth (Vue, store) {
  // check if token into local storage
  const authToken = Vue.ls.get(LS_KEY)
  if (!authToken) {
    return
  }

  store.dispatch('authLoaded', authToken)
  Vue.http.interceptors.push(function (request, next) {
    next(function (response) {
      // if 401, remove the token and refresh page
      if (response.status === 401) {
        deAuth(Vue, store)
      }
    })
  })
}

function storeMetaInLocalStorage (Vue, responseBody) {
  const authToken = JSON.parse(JSON.stringify(responseBody))

  // store data (token, roles) into localStorage
  Vue.ls.set(LS_KEY, authToken, responseBody.duration * 1000) // expiration: convert s => ms

  return authToken
}

const AuthPlugin = {}

AuthPlugin.install = function (Vue, options) {
  const store = options.store

  Vue.prototype.$auth = function (login, password) {
    return store.dispatch('createAuthToken', {login, password})
      .then((response) => {
        storeMetaInLocalStorage(Vue, response.body)
        doAuth(Vue, store)
      })
  }

  Vue.prototype.$deauth = function () {
    return store.dispatch('deleteAuthToken', {tokenId: Vue.ls.get(LS_KEY).id})
      .then((response) => {
        if (response.status === 204) {
          deAuth(Vue, store)
        }
      })
  }

  // todo check token validity accross server (should be expired client side -- no necessary so)
  // do auth according to localstorage data
  doAuth(Vue, store)
}

export default AuthPlugin
