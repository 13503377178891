;(function(){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _moment = require('moment');

var _moment2 = _interopRequireDefault(_moment);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
  props: ['miam'],
  computed: {
    isLoggedIn: function isLoggedIn() {
      return this.$store.getters.isLoggedIn;
    },
    isEatable: function isEatable() {
      return this.isLoggedIn && this.isEatableToday;
    },
    isEatableToday: function isEatableToday() {
      return (0, _moment2.default)(this.miam.dateToEat).isBefore((0, _moment2.default)());
    }
  },
  methods: {
    eatIt: function eatIt() {
      this.$store.dispatch('eatMiam', this.miam);
    }
  }
};
})()
if (module.exports.__esModule) module.exports = module.exports.default
var __vue__options__ = (typeof module.exports === "function"? module.exports.options: module.exports)
if (__vue__options__.functional) {console.error("[vueify] functional components are not supported and should be defined in plain js files using render functions.")}
__vue__options__.render = function render () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card',{staticClass:"mb-2",staticStyle:{"max-width":"25rem"},attrs:{"no-body":"","tag":"article"}},[_c('router-link',{attrs:{"to":{ name: 'get', params: { id: _vm.miam.id }}}},[_c('b-card-img',{attrs:{"src":_vm.miam.imageFile,"alt":_vm.miam.imageName,"top":true}})],1),_vm._v(" "),_c('div',{staticClass:"card-body"},[_c('router-link',{attrs:{"to":{ name: 'get', params: { id: _vm.miam.id }}}},[_c('h4',{staticClass:"card-title",domProps:{"textContent":_vm._s(_vm.miam.name)}})]),_vm._v(" "),(_vm.isEatable)?_c('b-button',{attrs:{"variant":"primary"},on:{"click":function($event){$event.preventDefault();_vm.eatIt($event)}}},[_vm._v("Mange-moi")]):_vm._e()],1)],1)],1)}
__vue__options__.staticRenderFns = []
if (module.hot) {(function () {  var hotAPI = require("vue-hot-reload-api")
  hotAPI.install(require("vue"), true)
  if (!hotAPI.compatible) return
  module.hot.accept()
  if (!module.hot.data) {
    hotAPI.createRecord("data-v-ec2af04c", __vue__options__)
  } else {
    hotAPI.reload("data-v-ec2af04c", __vue__options__)
  }
})()}