;(function(){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  name: 'SearchFormDumb',
  props: {
    searchIcon: {
      type: String,
      required: true
    },
    searchValue: {
      type: String,
      required: true
    }
  },
  data: function data() {
    return {
      searchFormOpened: false
    };
  },

  computed: {
    cSearchValue: {
      get: function get() {
        return this.searchValue;
      },
      set: function set(updatedValue) {
        this.$emit('updated-word', updatedValue);
      }
    }
  },
  methods: {
    toggleSearch: function toggleSearch() {
      this.searchFormOpened = !this.searchFormOpened;
      this.$emit('updated-word', '');
    }
  }
};
})()
if (module.exports.__esModule) module.exports = module.exports.default
var __vue__options__ = (typeof module.exports === "function"? module.exports.options: module.exports)
if (__vue__options__.functional) {console.error("[vueify] functional components are not supported and should be defined in plain js files using render functions.")}
__vue__options__.render = function render () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-nav-form',[(_vm.searchFormOpened || _vm.cSearchValue)?_c('b-form-input',{staticClass:"mr-sm-2 search-input",attrs:{"size":"sm","type":"text","placeholder":"Search"},nativeOn:{"keyup":function($event){if(!('button' in $event)&&_vm._k($event.keyCode,"esc",27,$event.key)){ return null; }_vm.toggleSearch($event)}},model:{value:(_vm.cSearchValue),callback:function ($$v) {_vm.cSearchValue=$$v},expression:"cSearchValue"}}):_vm._e(),_vm._v(" "),_c('b-nav-item',{staticClass:"my-2 my-sm-0",class:{clicked: _vm.searchFormOpened},attrs:{"id":"search-button","size":"sm","type":"submit"},on:{"click":function($event){$event.preventDefault();_vm.toggleSearch($event)}}},[_c('img',{attrs:{"src":_vm.searchIcon}})])],1)}
__vue__options__.staticRenderFns = []
if (module.hot) {(function () {  var hotAPI = require("vue-hot-reload-api")
  hotAPI.install(require("vue"), true)
  if (!hotAPI.compatible) return
  module.hot.accept()
  if (!module.hot.data) {
    hotAPI.createRecord("data-v-27ad3644", __vue__options__)
  } else {
    hotAPI.reload("data-v-27ad3644", __vue__options__)
  }
})()}