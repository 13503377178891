;(function(){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _moment = require('moment');

var _moment2 = _interopRequireDefault(_moment);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
  name: 'Get',
  props: ['id'],

  computed: {
    dateToEat: function dateToEat() {
      return (0, _moment2.default)(this.miam.dateToEat).format('L');
    },
    lastMiam: function lastMiam() {
      return (0, _moment2.default)(this.miam.lastMiam).format('L');
    },
    isLoggedIn: function isLoggedIn() {
      return this.$store.getters.isLoggedIn;
    },
    miam: function miam() {
      if (!this.$store.getters.miam) {
        return {};
      }

      return this.$store.getters.miam;
    }
  },

  mounted: function mounted() {
    var _this = this;

    this.$store.dispatch('selectItem', this.id).catch(function (httpResponse) {
      _this.$store.dispatch('addFlash', {
        type: 'danger',
        message: 'Erreur ' + httpResponse.status + ' - ' + httpResponse.statusText
      });
    });
  },

  methods: {
    eat: function eat() {
      this.$store.dispatch('eatMiam', this.miam);
    },
    edit: function edit() {
      this.$router.push({ name: 'edit', params: { id: this.id } });
    },
    remove: function remove() {
      var _this2 = this;

      var miamName = this.miam.name;
      this.$store.dispatch('removeMiam', this.miam).then(function () {
        _this2.$store.dispatch('addFlash', {
          type: 'success',
          message: miamName + ' a bien été supprimé'
        });
        _this2.$router.push({ name: 'home' });
      });
    }
  }
};
})()
if (module.exports.__esModule) module.exports = module.exports.default
var __vue__options__ = (typeof module.exports === "function"? module.exports.options: module.exports)
if (__vue__options__.functional) {console.error("[vueify] functional components are not supported and should be defined in plain js files using render functions.")}
__vue__options__.render = function render () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container"},[_c('h1',[_vm._v(_vm._s(_vm.miam.name))]),_vm._v("\n\n    Last miam: "+_vm._s(_vm.lastMiam)+"\n    "),_c('br'),_vm._v("\n    Date to eat: "+_vm._s(_vm.dateToEat)+"\n    "),_c('br'),_vm._v(" "),_c('b-img',{attrs:{"src":_vm.miam.imageFile,"fluid":"","alt":_vm.miam.imageName}}),_vm._v(" "),(_vm.isLoggedIn)?_c('b-button-group',[_c('b-button',{on:{"click":function($event){_vm.eat(_vm.miam)}}},[_vm._v("Mange")]),_vm._v(" "),_c('b-button',{on:{"click":_vm.edit}},[_vm._v("Edit")]),_vm._v(" "),_c('b-button',{directives:[{name:"b-modal",rawName:"v-b-modal.modal-delete",modifiers:{"modal-delete":true}}]},[_vm._v("Delete")])],1):_vm._e(),_vm._v(" "),_c('b-modal',{attrs:{"id":"modal-delete","title":"Confirm ?"},on:{"ok":_vm.remove}},[_c('p',{staticClass:"my-4"},[_vm._v("Do you want delete '"+_vm._s(_vm.miam.name)+"' ?")])])],1)}
__vue__options__.staticRenderFns = []
if (module.hot) {(function () {  var hotAPI = require("vue-hot-reload-api")
  hotAPI.install(require("vue"), true)
  if (!hotAPI.compatible) return
  module.hot.accept()
  if (!module.hot.data) {
    hotAPI.createRecord("data-v-00e5b13c", __vue__options__)
  } else {
    hotAPI.reload("data-v-00e5b13c", __vue__options__)
  }
})()}