;(function(){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _SearchFormSmart = require('./SearchFormSmart.vue');

var _SearchFormSmart2 = _interopRequireDefault(_SearchFormSmart);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.default = {
  name: 'Navbar',
  components: {
    SearchFormSmart: _SearchFormSmart2.default
  },
  props: {
    randomAsset: {
      type: String,
      required: true
    },
    addAsset: {
      type: String,
      required: true
    },
    eggAsset: {
      type: String,
      required: true
    },
    searchAsset: {
      type: String,
      required: true
    },
    userAsset: {
      type: String,
      required: true
    },
    useroffAsset: {
      type: String,
      required: true
    }
  },
  computed: {
    isLoggedIn: function isLoggedIn() {
      return this.$store.getters.isLoggedIn;
    }
  },
  methods: {
    random: function random() {
      if (this.$route.name === 'home') {
        this.$store.dispatch('random');
        return;
      }
      this.$router.push({ name: 'home' });
    },
    onLogoff: function onLogoff() {
      this.$deauth();
    }
  }

};
})()
if (module.exports.__esModule) module.exports = module.exports.default
var __vue__options__ = (typeof module.exports === "function"? module.exports.options: module.exports)
if (__vue__options__.functional) {console.error("[vueify] functional components are not supported and should be defined in plain js files using render functions.")}
__vue__options__.render = function render () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-navbar',{attrs:{"toggleable":"md","variant":"info"}},[_c('b-navbar-toggle',{attrs:{"target":"nav_collapse"}}),_vm._v(" "),_c('b-navbar-brand',{on:{"click":function($event){$event.preventDefault();_vm.random($event)}}},[_c('img',{attrs:{"src":_vm.randomAsset}}),_vm._v(" "),_c('p',{staticClass:"navbar-text"},[_vm._v("Les gourmandises de Marie")])]),_vm._v(" "),_c('b-collapse',{attrs:{"is-nav":"","id":"nav_collapse"}},[_c('b-navbar-nav',{staticClass:"right-img ml-auto"},[_c('search-form-smart',{attrs:{"search-icon":_vm.searchAsset}}),_vm._v(" "),(_vm.isLoggedIn)?_c('b-nav-item',{attrs:{"to":{ name: 'add'}}},[_c('img',{attrs:{"src":_vm.addAsset}})]):_vm._e(),_vm._v(" "),(_vm.isLoggedIn)?_c('b-nav-item',{attrs:{"to":{ name: 'lastEat'}}},[_c('img',{attrs:{"src":_vm.eggAsset}})]):_vm._e(),_vm._v(" "),(_vm.isLoggedIn)?_c('b-nav-item',{on:{"click":_vm.onLogoff}},[_c('img',{attrs:{"src":_vm.useroffAsset}})]):_vm._e(),_vm._v(" "),(!_vm.isLoggedIn)?_c('b-nav-item',{attrs:{"to":{ name: 'login'}}},[_c('img',{attrs:{"src":_vm.userAsset}})]):_vm._e()],1)],1)],1)}
__vue__options__.staticRenderFns = []
if (module.hot) {(function () {  var hotAPI = require("vue-hot-reload-api")
  hotAPI.install(require("vue"), true)
  if (!hotAPI.compatible) return
  module.hot.accept()
  if (!module.hot.data) {
    hotAPI.createRecord("data-v-18a2c2c2", __vue__options__)
  } else {
    hotAPI.reload("data-v-18a2c2c2", __vue__options__)
  }
})()}